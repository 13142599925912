import React from 'react';

const HeroSection = () => {
  return (
    <div
      className="relative bg-cover bg-center h-screen bg-gradient-to-r from-gray-800 to-gray-900   "
      >
      
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-4">
        
        <h1 className="text-4xl md:text-6xl font-bold">
          Stay Informed. Get Notified Instantly About Jobs & Updates That Matter to You.
        </h1>

        <p className="mt-4 text-lg md:text-2xl">
          Our notification system brings you real-time alerts on job postings and updates from key industry figures, delivered straight to your inbox or device.
        </p>

        <button className="mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg">
          Get Started for Free
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
