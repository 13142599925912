import React, { useState } from "react";
import { signUpUser } from "../api";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const SignUp = () => {
  const [json, setJson] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: ''
  });
  const [errorMessage, setErrorMessage] = useState(""); // For displaying error messages
  const navigate = useNavigate(); // Initialize useNavigate

  const handleChange = (e) => {
    setJson({
      ...json,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await signUpUser(json);
     localStorage.setItem('signUpEmail', json.email); 
      console.log('User signed up:', response);
     navigate('/verify');
      
    } catch (error) {
      setErrorMessage("Email is already in use. Please login.");
      console.error('Sign up failed:', error);
    }
  }

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-gray-700 via-gray-800 to-gray-950">
      <div className="w-full max-w-md bg-white shadow-md rounded-lg p-8 pt-2">
        <h1 className="text-3xl font-bold mb-6 text-center">Sign Up</h1>
        {errorMessage && ( 
          <div className="mb-4 text-red-500 text-center">
            {errorMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="first_name" className="block text-gray-700">
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              placeholder="First name"
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="last_name" className="block text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              placeholder="Surname"
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              placeholder="you@example.com"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          <div className="mb-6">
            <label htmlFor="phone_number" className="block text-gray-700">
              Phone
            </label>
            <input
              type="tel"
              id="phone_number"
              name="phone_number"
              placeholder="Mobile number"
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-indigo-700 transition"
          >
            Submit
          </button>

          <div className="my-2 text-center text-gray-500">OR</div>

          <p className="mt-2 text-center">
            Already have an account?{" "}
            <a href="/login" className="text-indigo-600 hover:underline">
              Login
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
