import React, { useState, useEffect } from 'react';
import { getProfile } from '../api'; 
import Header from './Header';

const ProfilePage = () => {
  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    email: '',
    telegram_chat_id: '',
    phone_number: '',
    credits: '',
    resume_text: ''
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile( ); 
        setProfile(data); 
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  return (
    <div>
      <Header/>
      <div className="min-h-screen flex flex-col items-center bg-gray-100 p-6">
        {profile && <div className="bg-white mt-10 p-6 rounded-lg shadow-md w-full max-w-md">
          <h1 className="text-2xl font-bold mb-4">Profile</h1>
          
          <div className="mb-4">
            <label className="block text-gray-700">First Name:</label>
            <p>{profile?.first_name}</p>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700">Last Name:</label>
            <p>{profile?.last_name}</p>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700">Email:</label>
            <p>{profile?.email}</p>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700">Telegram ID:</label>
            <p>{profile?.telegram_chat_id}</p>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700">Phone Number:</label>
            <p>{profile?.phone_number}</p>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700">Credits:</label>
            <p>{profile?.credits}</p>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700">Resume:</label>
            <p>{profile?.resume_text || 'No resume uploaded'}</p>
          </div>
          
          <div className="flex justify-between mt-6">
            <button className="bg-green-500 text-white px-4 py-2 rounded">Buy Credits</button>
            <button className="bg-purple-500 text-white px-4 py-2 rounded">Connect Telegram</button>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default ProfilePage;
