import React, { useState, useEffect } from 'react';
import { loginUser } from '../api';  // Assuming this is your API function
import { useNavigate } from "react-router-dom"; 

const LoginForNewSignup = () => {
    const [email, setEmail] = useState(''); 
    const [verification_code, setVerificationCode] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); 
    const navigate = useNavigate();  

    useEffect(() => {
        const storedEmail = localStorage.getItem('signUpEmail'); 
        if (storedEmail) {
            setEmail(storedEmail); 
        } else {
            setError('No email found for verification. Please sign up first.');
        }
        setLoading(false); 
    }, []);

    const handleVerificationSubmit = async (e) => {
        e.preventDefault();
        setError(null);
       
        try {
            const verificationData = { email, verification_code };  
            const response = await loginUser(verificationData);

            if (response && response.data) {
                const { token, subscriber } = response.data;  // Assuming the API returns token and subscriber info in data
                
                if (token) {
                    localStorage.setItem('authToken', token);
                    console.log('Login successful, token stored:', token);
                    console.log('Subscriber details:', subscriber);
                    navigate('/home');
                } else {
                    setError('Token not found. Please try again.');
                }
            } else {
                setError('Invalid response from the server. Please try again.');
            }

        } catch (error) {
            console.error('Verification failed:', error);
            setError('Verification failed. Please check your code and try again.');
        }
    };

    if (loading) {
        return <p className="text-gray-500">Loading email...</p>;
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-800 via-gray-700 to-gray-950">
            <h1 className="text-3xl font-bold mb-6 text-white">Complete Signup</h1>
            <form 
                onSubmit={handleVerificationSubmit} 
                className="bg-white p-6 rounded-lg shadow-md max-w-2xl md:w-2/5"
            > 
                <h1 className='text-center font-bold mb-2'>Verification Code sent to your email</h1>
                {email ? (
                    <>
                        <label htmlFor="email" className="block text-gray-700">Email</label>
                        <input 
                            type="email"
                            id="email" 
                            className="mb-4 text-gray-700 border p-2 rounded w-full focus:outline-none" 
                            value={email} 
                            disabled
                        />
                        <div className="mb-4">
                            <label htmlFor="verificationCode" className="block text-gray-700">Verification Code</label>
                            <input
                                type="text"
                                placeholder="Enter verification code"
                                value={verification_code}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                required
                                className="border p-2 rounded w-full focus:outline-none focus:ring-1 focus:ring-indigo-400"
                            />
                        </div>
                        {error && <p className="text-red-500">{error}</p>}
                        <button 
                            type="submit" 
                            className="bg-indigo-600 text-white p-2 rounded-lg w-full hover:bg-indigo-700 transition"
                        >
                            Verify
                        </button>
                    </>
                ) : (
                    <p className="text-gray-500">No email found for verification.</p>
                )}
            </form>
        </div>
    );
};

export default LoginForNewSignup;
