import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';

const HomePage = () => {
    return (
        <div className="min-h-screen bg-gray-100">
          <Header/>
            <main className=" mt-16 p-8">
                <h2 className="text-2xl font-bold mb-4">Your Subscriptions</h2>
                <p className="text-gray-700 ">
                    You don't have any subscriptions yet.{' '}
                    <Link to="/create-subscription" className="text-indigo-600 hover:underline">
                        Create Subscription?
                    </Link>
                </p>
            </main>
        </div>
    );
};

export default HomePage;
