import React, { useState } from 'react';
import { createSubscription, findPerson } from '../api'; // Assuming Subscriptions function is exported from api.js

const countries = [
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", 
  "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", 
  "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", 
  "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", 
  "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", 
  "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic", 
  "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", 
  "Croatia", "Cuba", "Cyprus", "Czech Republic", "Democratic Republic of the Congo", 
  "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", 
  "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", 
  "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", 
  "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", 
  "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", 
  "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", 
  "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", 
  "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", 
  "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", 
  "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", 
  "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", 
  "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", 
  "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", 
  "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", 
  "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", 
  "Oman", "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", 
  "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", 
  "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", 
  "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", 
  "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", 
  "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", 
  "Solomon Islands", "Somalia", "South Africa", "South Africa", "Spain", 
  "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", 
  "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", 
  "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", 
  "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", 
  "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", 
  "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];
const roles = [
    "Software Engineer","Frontend Developer","Backend Developer","Full Stack Developer","Data Scientist","Machine Learning Engineer","Data Engineer",
    "DevOps Engineer","Cloud Engineer",
    "Mobile App Developer","iOS Developer",
    "Android Developer", "Security Engineer", "Network Engineer","UI/UX Designer","Product Manager","Technical Project Manager","IT Support Specialist","System Administrator","Quality Assurance Engineer","Test Automation Engineer","Embedded Systems Engineer","Blockchain Developer","Game Developer","VR/AR Developer","AI Research Scientist","Robotics Engineer","Database Administrator","Solutions Architect","Site Reliability Engineer","Technical Writer","Data Analyst","Business Intelligence Analyst","Data Visualization Specialist","Cybersecurity Analyst","Computer Vision Engineer","Natural Language Processing Engineer",
    "IoT Engineer","Digital Marketing Specialist","SEO Specialist","IT Consultant","Software Architect",
    "Bioinformatics Specialist","Technical Recruiter",
    "Technical Support Engineer","Firmware Engineer","ERP Consultant", "Chief Technology Officer", "Chief Information Officer",
    "Scrum Master", "Agile Coach","IT Auditor","Penetration Tester","Computer Scientist","Software Development Manager","Hardware Engineer", "Tech Support Analyst"
  ];
  
  const techSkills = [
    "Data Analysis", "Machine Learning", "Deep Learning", "TensorFlow", "PyTorch", "Pandas", "NumPy", "SQL for Data Analysis", 
    "Agile Methodologies", "UX/UI Design", "Mobile Development (iOS/Android)", "Web Development", "Software Testing and Quality Assurance", 
    "JavaScript", "Python", "React", "Node.js", "Java", "C#", "Ruby", "TypeScript", "SQL", "NoSQL", "AWS", "Azure", 
    "Google Cloud", "Docker", "Kubernetes", "Angular", "Vue.js", "HTML", "CSS", "GraphQL", "Rust", "Go", "Swift", 
    "Objective-C", "Django", "Flask", "Spring", "Express", "Keras", "Apache", "Nginx", "Jenkins", "Git", "Bitbucket", 
    "Terraform", "Ansible", "Scrum", "Cybersecurity", "Penetration Testing", "Ethical Hacking", "Microservices", 
    "Blockchain", "Internet of Things (IoT)", "Data Visualization", "MATLAB", "Tableau", "Power BI", "Redis", 
    "Elasticsearch", "Sass", "LESS", "Figma", "Adobe XD", "Ruby on Rails", "Unity", "Game Development", 
    "Voice User Interface (VUI)", "Natural Language Processing (NLP)", "Computer Vision", "Chatbot Development", 
    "API Development", "Web Scraping", "SEO", "Digital Marketing", "Content Management Systems (CMS)", "WordPress", 
    "Shopify", "Magento","Critical Thinking", "Problem Solving", "Project Management", "Team Collaboration", "Communication", 
    "Time Management","Next js", "Adaptability", "Creativity", "Attention to Detail", "Research Skills", "User Research", 
    "Customer Service", "Public Speaking", "Technical Writing", "Data Interpretation", "Business Analysis", 
    "Strategic Planning", "Negotiation", "Networking", "Presentation Skills", "Conflict Resolution", "Mentoring", 
    "Coaching", "Decision Making", "Emotional Intelligence", "Change Management"
  ];
  
  const CreateSubscriptionPage = () => {
    const [entityType, setEntityType] = useState('');
    const [channels, setChannels] = useState([]);
    const [cadence, setCadence] = useState('');
    const [jobDetails, setJobDetails] = useState({
      roles: [],
      compensation: '',
      skills: [],
      supportsRelocation: false,
      isRemote: false,
      jobType: '',
      location: ''
    });
    const [personDetails, setPersonDetails] = useState({
      searchType: '',
      personSearch: ''
    });
    const [personSearch, setPersonSearch] = useState(""); // State for person search
    const [skillSearch, setSkillSearch] = useState(""); // State for skill search
    const [suggestions, setSuggestions] = useState([]);
    const [isPersonFocused, setIsPersonFocused] = useState(false);
    const [isSkillFocused, setIsSkillFocused] = useState(false); 

    const handleSearchChange = (e) => {
        const value = e.target.value;
      
        // Check which input is focused
        if (isPersonFocused) {
          setPersonSearch(value);
          // If user presses Ctrl + Enter, trigger person search
          if (value && e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            searchPerson(value); // Call the search function for person
          }
        } else if (isSkillFocused) {
          setSkillSearch(value);
          // Filter suggestions based on input
          if (value) {
            const filteredSuggestions = techSkills.filter((skill) =>
              skill.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
          } else {
            setSuggestions([]);
          }
        }
      };

      
      const searchPerson = async () => {
        try {
          const response = await findPerson();
          const data = await response.json();
          console.log(data);
          setPersonDetails(data); 
        } catch (error) {
          console.error("Error searching for person:", error);
        }
      };
  
    const handleSuggestionClick = (skill) => {
        if (!jobDetails.skills.includes(skill)) {
          setJobDetails((prev) => ({
            ...prev,
            skills: [...prev.skills, skill],
          }));
        }
        setPersonSearch(''); 
        setSuggestions([]);
      };
      
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const subscriptionData = {
        entityType,
        channels,
        cadence,
        jobDetails: entityType === 'Job' ? jobDetails : undefined,
        personDetails: entityType === 'Person' ? personDetails : undefined,
        personSearch,
        skillSearch,
      };
  
      try {
        await createSubscription(subscriptionData, 'POST');
        alert('Subscription created successfully!');
      } catch (error) {
        console.error('Error creating subscription:', error);
        alert('Failed to create subscription');
      }
    };
  
    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center py-10 px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl w-full space-y-4 bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold text-center text-gray-900">Create Subscription</h2>
      
            <form onSubmit={handleSubmit} className="space-y-3">
              <div>
                <label className="block text-gray-700">Entity Type:</label>
                <select
                  value={entityType}
                  onChange={(e) => setEntityType(e.target.value)}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Select</option>
                  <option value="Job">Job</option>
                  <option value="Person">Person</option>
                </select>
              </div>
      
              <div>
                <label className="block text-gray-700">Channels</label>
                <select
                  multiple
                  value={channels}
                  onChange={(e) => setChannels([...e.target.selectedOptions].map(option => option.value))}
                  className="mt-1 block h-24 w-full  border  border-gray-300 bg-white rounded-sm shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option  className= "w-full p-1"value="WhatsApp">WhatsApp</option>
                  <option className= "w-full p-1"value="Telegram">Telegram</option>
                  <option className= "w-full p-1" value="Email">Email</option>
                </select>
                <p className="text-sm text-gray-500">(Hold Ctrl or Command to select multiple)</p>
              </div>
      
              {/* Cadence Selection */}
              <div>
                <label className="block text-gray-700">Cadence:</label>
                <select
                  value={cadence}
                  onChange={(e) => setCadence(e.target.value)}
                  className="mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-sm shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Bi-Weekly">Bi-Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>
      
              {/* Job Details */}
              {entityType === 'Job' && (
                <>
                  <div>
                    <label className="block text-gray-700">Roles (Hold Ctrl or Command to select multiple):</label>
                    <select
                      multiple
                      value={jobDetails.roles}
                      onChange={(e) => setJobDetails({ ...jobDetails, roles: [...e.target.selectedOptions].map(option => option.value) })}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      {roles.map((role) => (
                        <option key={role} value={role}>{role}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700">Compensation:</label>
                    <input
                      type="text"
                      placeholder='e.g $100'
                      value={jobDetails.compensation}
                      onChange={(e) => setJobDetails({ ...jobDetails, compensation: e.target.value })}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
      
                  <div>
  <label className="block text-gray-700">Selected Skills:</label>
  <div className="flex flex-wrap gap-2 mt-1 mb-2">
    {jobDetails.skills.map((skill, index) => (
      <span key={index} className="bg-indigo-100 text-indigo-800 text-sm px-2 py-1 rounded">
        {skill}
      </span>
    ))}
  </div>
</div>

<div>
  <label className="block text-gray-700">Skills:</label>
  <input
    type="text"
    value={skillSearch}
    onFocus={() => {
        setIsSkillFocused(true);
        setIsPersonFocused(false);
      }}
      onBlur={() => setIsSkillFocused(false)}
      onChange={handleSearchChange}
    placeholder="Type to search..."
    className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
  />
  {  suggestions.length > 0 && (
    <ul className="mt-2 max-h-32 overflow-auto border border-gray-300 rounded-md shadow-sm">
      {suggestions.map((skill) => (
        <li
          key={skill}
          onClick={() => handleSuggestionClick(skill)}
          className="cursor-pointer py-1 px-3 hover:bg-gray-200"
        >
          {skill}
        </li>
      ))}
    </ul>
  )}
</div>

      
                  {/* Relocation and Remote Options */}
                  <div >
                  <input
                      type="checkbox"
                      checked={jobDetails.supportsRelocation}
                      onChange={(e) => setJobDetails({ ...jobDetails, supportsRelocation: e.target.checked })}
                      className="mt-2 mx-2"
                    />
                    <label className=" text-gray-700">Supports Relocation</label>
                  </div>
      
                  <div >
                  <input
                      type="checkbox"
                      checked={jobDetails.isRemote}
                      onChange={(e) => setJobDetails({ ...jobDetails, isRemote: e.target.checked })}
                      className="mt-2 mx-2"
                    />
                    <label className="text-gray-700">Remote Position</label>
                  </div>
                  <div>
          <label className="block text-gray-700">Job Type:</label>
          <select
            value={jobDetails.jobType}
            onChange={(e) => setJobDetails({...jobDetails,jobType: e.target.value})}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">Select</option>
            <option value="Part-time">Part-time</option>
            <option value="Internship">Internship</option>
            <option value="Contract">Contract</option>
            <option value="Temporary">Temporary</option>
            <option value="Full-time">Full-time</option>
          </select>
        </div>
      
                  <div>
                    <label className="block text-gray-700">Location:</label>
                    <select
                      value={jobDetails.location}
                      onChange={(e) => setJobDetails({ ...jobDetails, location: e.target.value })}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <option value="">Select Country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}

          {/* Person Details */}
          {entityType === 'Person' && (
            <>
                <div>
                <label className="block text-gray-700">Search Type:</label>
                <select
                  value={personDetails.searchType}
                  onChange={(e) => setPersonDetails({ ...personDetails, searchType: e.target.value })}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Select</option>
                  <option value="Wiki Search">Wiki Search</option>
                  <option value="Google Search">Google Search</option>
                </select>
              </div>

              <div className="relative">
                <label className="block text-gray-700">Search for a Person:</label>
                <input
                  type="text"
                  value={personSearch}
                  onFocus={() => {
                    setIsPersonFocused(true);
                    setIsSkillFocused(false);
                  }}
                  onBlur={() => setIsPersonFocused(false)}
                  onChange={handleSearchChange}
                  onKeyDown={handleSearchChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />{' Press Ctrl + Enter (or Cmd + Enter on Mac) to search.'}
              </div>
            </>
          )}

          <button
            type="submit"
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Create Subscription
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateSubscriptionPage;
