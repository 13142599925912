import React from "react";
import HeroSection from "./landingpage/HeroSection";
import FeaturesSection from "./landingpage/FeaturesSection";
import BenefitsSection from "./landingpage/Benefits";
import TestimonialSection from "./landingpage/Testimonials";
import SignUpSection from "./landingpage/SignUp";
import Header from "./landingpage/Header";
import Footer from "./landingpage/Footer";

const LandingPage=()=>{
    return(
        <div>
            <Header/>
            <HeroSection/>
            <FeaturesSection/>
            <BenefitsSection/>
            <TestimonialSection/>
            <SignUpSection/>
            <Footer/>
        </div>
    )
}

export default LandingPage;