import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faXTwitter, faInstagram, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-8">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-start">
                <div className="mb-6">
                    <h3 className="text-lg font-semibold">Contact Us</h3>
                    <p>Email: support@makerstudio.com</p>
                    <p>Phone: (123) 456-7890</p>
                </div>
                <div className="mb-6">
                    <h3 className="text-lg font-semibold">Important Links</h3>
                    <ul>
                        <li>
                            <a href="/privacy-policy" className="text-gray-400 hover:text-white">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/terms-of-service" className="text-gray-400 hover:text-white">Terms of Service</a>
                        </li>
                        <li>
                            <a href="/faq" className="text-gray-400 hover:text-white">FAQ</a>
                        </li>
                        <li>
                            <a href="/about" className="text-gray-400 hover:text-white">About Us</a>
                        </li>
                    </ul>
                </div>
                <div className="mb-6">
                    <h3 className="text-lg font-semibold">Follow Us</h3>
                    <div className="flex space-x-4">
                        <a href="/" className="text-gray-400 hover:text-blue-500" aria-label="Follow us on Facebook">
                            <FontAwesomeIcon icon={faFacebook} />
                            <span className="sr-only">Facebook</span>
                        </a>
                        <a href="/" className="text-gray-400 hover:text-white" aria-label="Follow us on Twitter">
                            <FontAwesomeIcon icon={faXTwitter} />
                            <span className="sr-only">Twitter</span>
                        </a>
                        <a href="/" className="text-gray-400 hover:text-pink-500" aria-label="Follow us on Instagram">
                            <FontAwesomeIcon icon={faInstagram} />
                            <span className="sr-only">Instagram</span>
                        </a>
                        <a href="/" className="text-gray-400 hover:text-gray-600" aria-label="Follow us on GitHub">
                            <FontAwesomeIcon icon={faGithub} />
                            <span className="sr-only">GitHub</span>
                        </a>
                        <a href="/" className="text-gray-400 hover:text-blue-600" aria-label="Follow us on LinkedIn">
                            <FontAwesomeIcon icon={faLinkedin} />
                            <span className="sr-only">LinkedIn</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="text-center mt-6 border-t border-gray-700 pt-4">
                <p className="text-gray-400">&copy; 2024 Makers Studio. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
