import React from 'react';


const testimonials = [
  {
    name: "Mehwish",
    text: "I always stayed connected with key job updates and news. This platform made it easy to stay informed!",
    image: "/path/to/image1.jpg",
  },
  {
    name: "Elizabeth Jeff",
    text: "I found my dream job thanks to the real-time notifications. Highly recommended!",
    image: "/path/to/image2.jpg",
  },
  {
    name: "Emily Thomas",
    text: "Being up-to-date with the latest industry trends has never been easier. I'm loving this service.",
    image: "/path/to/image3.jpg",
  },
];

const TestimonialSection = () => {
  return (
    <div className="py-16 bg-white">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        
        <div className="md:w-1/2 px-6 mb-8 md:mb-0">
          <h2 className="text-3xl font-bold mb-4">What Our Users Say</h2>
          <p className="text-gray-600 mb-6">
            Our users love staying connected with personalized job alerts and industry updates. Hear what they have to say!
          </p>
          <button className="bg-purple-500 text-white px-6 py-2 rounded-lg hover:bg-purple-600 transition">
            View More
          </button>
        </div>

        <div className="md:w-1/2 space-y-6 relative">
         
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg flex items-center relative transform ">
            <img
              src={testimonials[0].image}
              alt={testimonials[0].name}
              className="w-16 h-16 rounded-full mr-4"
            />
            <div>
              <h3 className="text-lg font-semibold">{testimonials[0].name}</h3>
              <p className="text-gray-600 italic mt-2">"{testimonials[0].text}"</p>
            </div>
            
            <span className="absolute top-2 right-2 text-4xl text-purple-300">“</span>
          </div>

          <div className="bg-gray-100 p-6 rounded-lg shadow-lg flex items-center relative transform ">
            <img
              src={testimonials[1].image}
              alt={testimonials[1].name}
              className="w-16 h-16 rounded-full mr-4"
            />
            <div>
              <h3 className="text-lg font-semibold">{testimonials[1].name}</h3>
              <p className="text-gray-600 italic mt-2">"{testimonials[1].text}"</p>
            </div>
            <span className="absolute top-2 right-2 text-4xl text-purple-300">“</span>
          </div>

          <div className="bg-gray-100 p-6 rounded-lg shadow-lg flex items-center relative transform">
            <img
              src={testimonials[2].image}
              alt={testimonials[2].name}
              className="w-16 h-16 rounded-full mr-4"
            />
            <div>
              <h3 className="text-lg font-semibold">{testimonials[2].name}</h3>
              <p className="text-gray-600 italic mt-2">"{testimonials[2].text}"</p>
            </div>
            <span className="absolute top-2 right-2 text-4xl text-purple-300">“</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
