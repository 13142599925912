import React from 'react';

const BenefitsSection = () => {
  return (
    <div className="py-16 bg-white">
      <div className="container mx-auto text-center px-6 md:px-12 lg:px-24">
        <h2 className="text-3xl md:text-4xl font-bold mb-8">Why Choose Our Internet Feed Service?</h2>
        
        <p className="text-lg mb-12">
          Stay informed, stay ahead. Our notification system gives you the power to tailor alerts and updates, making sure you never miss a critical opportunity.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="bg-gray-100 rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold">Reliable Alerts</h3>
            <p className="text-gray-700 mt-2">
              Never miss a job posting or critical update, with real-time notifications sent directly to you.
            </p>
          </div>

          <div className="bg-gray-100 rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold">Tailored to Your Needs</h3>
            <p className="text-gray-700 mt-2">
              Customize the types of updates and alerts you receive based on your preferences.
            </p>
          </div>

          <div className="bg-gray-100 rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold">Stay Ahead of the Curve</h3>
            <p className="text-gray-700 mt-2">
              Get notified the moment opportunities arise, helping you stay competitive and proactive.
            </p>
          </div>

          <div className="bg-gray-100 rounded-lg shadow-md p-6">
            <h3 className="text-xl font-semibold">Cross-Platform Notifications</h3>
            <p className="text-gray-700 mt-2">
              Receive updates on your preferred device—whether it's via email, SMS, or in-app alerts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsSection;
