import axios from "axios";

// const API_BASE_URL = 'http://localhost:8080/api'; 
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// Replace with your API base URL

// Sign up user
export const signUpUser = async (userData) => {
    try {
        console.log('User Data: ', userData);
        const { data } = await axios.post(`${API_BASE_URL}/register`, userData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log(data);
        return data;
    } catch (error) {
        console.error('Sign up error:', error);
        throw error;
    }
};

// Login user
export const loginUser = async (credentials) => {
    try {
        console.log('Credentials: ', credentials);
        const { data } = await axios.post(`${API_BASE_URL}/login`, credentials, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return data;
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

// Verify user
export const verifyUser = async (code) => {
    try {
        console.log('Verification code: ', code);
        const { data } = await axios.post(`${API_BASE_URL}/send-verification`, code, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log(data);
        return data;
    } catch (error) {
        console.error('Verification error:', error);
        throw error;
    }
};

const generateHeaders = () => {
    const bearerToken = localStorage.getItem('token');
    const options = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    if (bearerToken) {
        options.Authorization = `Bearer ${bearerToken}`;
    }

    return options;
}

const instance = axios.create({
    baseURL: API_BASE_URL
  });


instance.interceptors.request.use(function (config) {
    console.log('config setter')
    return {...config, headers: {...config.headers, ...generateHeaders()  }};
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

export const getProfile = async () => {
    try {
        const { data } = await instance.get(`/profile`);
        console.log(data);
        return data;
    } catch (error) {
        console.error('Profile error:', error);
        throw error;
    }
};

export const buyCredits = async (credits) => {
    try {
        const { data } = await instance.post(`${API_BASE_URL}/buy-credits`, credits);
        console.log(data);
        return data;
    } catch (error) {
        console.error('Buy credits error:', error);
        throw error;
    }
};

// Payment callback
export const paymentCallback = async (session_id) => {
    try {
        const bearerToken = localStorage.getItem('token');
        const { data } = await axios.get(`${API_BASE_URL}/payment/callback?session_id=${session_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
        console.log(data);
        return data;
    } catch (error) {
        console.error('Payment callback error:', error);
        throw error;
    }
};

// Get credits history
export const getCreditsHistory = async () => {
    try {
        const { data } = await instance.get(`${API_BASE_URL}/credit-history`);
        console.log(data);
        return data;
    } catch (error) {
        console.error('Credits history error:', error);
        throw error;
    }
};

// Get subscriptions
export const getSubscriptions = async () => {
    try {
        const { data } = await instance.get(`${API_BASE_URL}/subscriptions`);
        console.log(data);
        return data;
    } catch (error) {
        console.error('Subscriptions error:', error);
        throw error;
    }
};

// Create subscription
export const createSubscription = async (subscription) => {
    try {
        const { data } = await instance.post(`${API_BASE_URL}/subscriptions`, subscription);
        console.log(data);
        return data;
    } catch (error) {
        console.error('Create subscription error:', error);
        throw error;
    }
};

// Delete subscription
export const deleteSubscription = async (id) => {
    try {
        const bearerToken = localStorage.getItem('token');
        const { data } = await axios.delete(`${API_BASE_URL}/subscriptions/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
        console.log(data);
        return data;
    } catch (error) {
        console.error('Delete subscription error:', error);
        throw error;
    }
};

// Get people alerts
export const getPeopleAlerts = async () => {
    try {
        const bearerToken = localStorage.getItem('token');
        const { data } = await axios.get(`${API_BASE_URL}/people_alerts?page=&page-size`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
        console.log(data);
        return data;
    } catch (error) {
        console.error('People alerts error:', error);
        throw error;
    }
};

// Search person
export const findPerson = async (query, source) => {
    try {
        const queryString = new URLSearchParams(query).toString();
        const bearerToken = localStorage.getItem('token');
        const { data } = await axios.get(`${API_BASE_URL}/search_person?${queryString}&${source}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
        console.log(data);
        return data;
    } catch (error) {
        console.error('Search person error:', error);
        throw error;
    }
};

// Create person profile
export const createPersonProfile = async (person) => {
    try {
        const bearerToken = localStorage.getItem('token');
        const { data } = await axios.post(`${API_BASE_URL}/person_profile`, person, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
        console.log(data);
        return data;
    } catch (error) {
        console.error('Create person profile error:', error);
        throw error;
    }
};

// Get person profile
export const getPersonProfile = async (personId) => {
    try {
        const bearerToken = localStorage.getItem('token');
        const { data } = await axios.get(`${API_BASE_URL}/person_profile/${personId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
        console.log(data);
        return data;
    } catch (error) {
        console.error('Get person profile error:', error);
        throw error;
    }
};

// Get job listings
export const getJobListings = async () => {
    try {
        const bearerToken = localStorage.getItem('token');
        const { data } = await axios.get(`${API_BASE_URL}/jobs?page=`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`
            }
        });
        console.log(data);
        return data;
    } catch (error) {
        console.error('Job listings error:', error);
        throw error;
    }
};
